import useAuthStore from '~/stores/auth'
import { JWT_ACCESS_TOKEN } from '~/src/constants/common'

type onInitCallback = (isAuthenticated: boolean) => void | Promise<void>

export const useAuthentication = (onInit?: onInitCallback) => {
  const { $cookies } = useNuxtApp()
  const authStore = useAuthStore()

  const authenticated = ref(null)

  const authenticationChecked = computed(() => authenticated.value !== null)

  const authenticationChanged = computed(() => authStore.jwtToken)
  const loggedIn = computed(() => authStore.authenticated)

  const isAuthenticated = () => {
    authenticated.value = !!$cookies.get(JWT_ACCESS_TOKEN)
    return authenticated.value
  }

  watch(authenticationChanged, (newValue, oldValue) => {
    if (!!newValue === !!oldValue) return
    authenticated.value = !!newValue

    if (typeof onInit === 'function') {
      onInit(!!newValue)
    }
  })

  return {
    authenticated,
    authenticationChanged,
    loggedIn,
    authenticationChecked,
    isAuthenticated,
  }
}

export default useAuthentication
